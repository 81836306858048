/* Reset default browser styles */
html, body, div, h1, h2, h3, p, ul, ol, li, a, img {
  margin: 0;
  padding: 0;
  border: 0;
}

/* Set a background color and font for the entire body */
body {
  font-family: 'Syne', sans-serif;
  background-color: #FBF9FF;
  color: #59585C;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Define primary and secondary colors */
:root {
  --primary-color: #7543E3; /* Change to your primary color */
  --secondary-color: #100429; /* Change to your secondary color */
  --tertiary-color:#59585C;
}

/* Basic link styles */
a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover,
a:active {
  color: var(--secondary-color);

}
.bg-white{
  background: #F8F8F8;

}
.bg-grey{
  background: #F1ECFC;

}
.bg-
.mobile-nav{
  display: none;
}
.btn-transparent{
background: none;
border: none;
font-size: 25px;
color: var(--primary-color);
}
aside {
display: none;
}
.open-sidebar{
display: none;
}
/** Button **/
.bordered-rounded-button {
  display: inline-block;
  padding: 8px 40px;
  background-color: transparent;
  color: var(--primary-color); /* Set your desired text color */
  font-size: 15px;
  font-weight: 500;
  border: 1.5px solid var(--primary-color); /* Set your desired border color and width */
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 20px; /* Adjust the border radius for rounded corners */
}

.bordered-rounded-button:hover {
  background-color:var(--primary-color); /* Background color on hover */
  color: #fff; /* Text color on hover */
}

/* Container for centering content */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.container h2{
  color:var(--secondary-color)
}
.container-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
 
}
.breadcrumb{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 112%;
  background: url('./../images/background.png');
  background-repeat: no-repeat;
  margin-top: 30px;
}
.breadcrumb-left{
  width:40%;
  margin-left: 5%;
}
.breadcrumb-left h3{
  color:var(--secondary-color);
  font-weight: 600;
  font-size: 28px;
}
.breadcrumb-right{
  width: 40%;
}
.breadcrumb-right img{
  width: 70%;
  margin-left: 15%;
}
.breadcrumb-link{
  color:#59585C
}
.breadcrumb-left span{
  color:var(--primary-color);
  padding-left: 4px;
}
@media (min-width: 768px) {

  
}


/* Responsive media query for landscape */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
  .navbar{
    width: 108% !important;
  }
  .about{
    flex-direction: column;
    width: 108% !important;

  }
  .breadcrumb{
    margin-top: 5px;
    width:110%;
  }
  .breadcrumb-right{
    display: none;
  }
  .breadcrumb-left{
    width: 100%;
  }
  .breadcrumb-left h3{
    font-size: 25px;
  }
  .about-left{
   width: 100% !important;
  }
  .about-right{
    width: 100% !important;
    margin-top: 30px !important;

  }
  .features{
    flex-direction: column;
    width: 108% !important;

  }
  .features-left{
width: 100% !important;
  }
  .features-right{
    width: 100% !important;
    margin-top: 30px !important;

  }
  .team{
    width: 108% !important;

  }
  .team-social{
    width: 40% !important;
  }
  .cta{
  background-size: cover !important;
  }
 .nav-links{
  display: none !important;
 }
 .signup-button{
  display: none;
 }
 .footer-container{
  flex-direction: column !important;
 }
.footer-left{
  width:100% !important
}
.footer-middle{
  align-items: flex-start !important;
  width:100% !important;
}
.footer-social{
  width: 100px !important;
}
.footer-right{
  width:100% !important
}
}


.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  position: sticky;
  top: 0;
  background:#fbf9ff ;
  z-index: 100;
  width:100%;
  height:80px;
}

.logo {
  text-align: center;
  padding: 10px;
  font-size: 24px;
}
.logo img{
  width:150px;
}
.nav-row{
  display: flex;
  justify-content: space-between;
}
.nav-row li{
  list-style: none;
}
.anchor{
  text-decoration: none;
  color: var(--tertiary-color);
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
}
.anchor:hover{
  color:var(--tertiary-color);
}
.nav-links {
  display: flex;
  justify-content: center;
  list-style: none;
  transition: 0.4s ease-out;
  transition-delay: 0.1s;
}

/* Apply active styles to the active link */
.anchor.active {
  color: var(--primary-color);
}

.nav-row li a {
  text-decoration: none;
  color: var(--primary-color);
  position: relative;
}

.nav-row li a.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  margin-left: 30%;
  height: 1px; /* Adjust the height/thickness of the underline as needed */
  background-color: var(--secondary-color);
}


.signup-button {
  text-align: center;
  padding: 10px;
}

.signup-button button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
.signup-button button:hover {
 background:var(--secondary-color) ;
}
.row {
display: flex;
justify-content: space-between; /* Distribute items evenly */
flex-wrap: wrap; /* Wrap items to the next row on smaller screens */
margin: 0 -5px; /* Add spacing between items */
}


.row.row-flex-end {
justify-content: flex-end; /* Align items to the right end of the row */
}

.item {
width: calc(50% - 10px); /* Two items in each row with 10px spacing in between */
box-sizing: border-box; /* Include padding and border in the width */
margin: 0 5px 10px 0; /* Adjust spacing between items */
padding: 20px;
background-color: #e0e0e0;
text-align: center;
}

.hero-image{
  width:85%;
  margin-left: 15%;
}
/* Media query for mobile devices */
@media (max-width: 767px) {
.item {
  width: 100%; /* Make items full width on mobile devices */
  margin: 0 0 10px 0; /* Adjust spacing between items */
}
}

.hero-left{
  width:50%;
}
.hero-left h3{
  color:var(--secondary-color);
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin: 0;
  padding-top: 8px;
}
.hero-left h5{
  color:var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
.hero-left p{
  color:var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  padding-top: 10px;
  margin-bottom: 22px;
}
.hero-right{
  width:50%;
}
.welcome{
  width:100%;
  margin-top: 80px;
}
.about{
  width: 98.7vw;
  margin-top: 70px;
  background: #F1ECFC;
  padding-top: 70px;
  padding-bottom: 70px;
}
.about-left{
  width:40%;
  margin-top: 50px;
}
.about-left h3{
  color:var(--secondary-color);
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
  margin: 0;
  padding-top: 3px;
}
.about-left h5{
  color:var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.about-left p{
  color:var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  padding-top: 10px;
  margin-bottom: 22px;
}
.about-right{
  display: flex;
  align-items: center;
  justify-content: center;
  width:40%;
}
.about-image{
  width:100%;
}

/** About Ends Here**/

/** Products Starts Here **/
.products{
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: -50px;
  width: 98.7vw;
  padding-top: 70px;
  padding-bottom: 70px;
}
.row-left{
  width:45%;
}

.products li{
  list-style:circle;

}
.products h3{
  color:var(--secondary-color);
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  padding-top: 3px;
}
.products h5{
  color:var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.products p{
  color:var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  padding-top: 10px;
  margin-bottom: 22px;
}
.row-right{
  width:45%;
}
.product-image{
  width:90%;
}

.flex-space-around{
  align-items: center;
  justify-content: space-around;
}
.features{
  width: 98.7vw;
  margin-top: 10px;
  background-image: url(./../images/background.png);
  background-repeat: no-repeat;
  padding-bottom: 50px;
}
.features-left{
  width:40%;
  margin-top: 50px;
  
}
.features-left h3{
  color:var(--secondary-color);
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin: 0;
  padding-top: 3px;
}
.features-left h5{
  color:var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
.features-left p{
  color:var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  padding-top: 10px;
  margin-bottom: 22px;
}
.features-right{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:40%;
}
.features-image{
  width:100%;
  margin-bottom: 30px;
}

.features-wrapper{
  margin-bottom: 0px;
  padding:30px;
}
.features-wrapper:hover{
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.1); 
}
.features-wrapper h5{
  color:rgba(20, 8, 48, 1);
  font-weight: 600;
  font-size: 18px;
  margin:0;
}
.features-wrapper p{
  color:rgba(89, 88, 92, 1);
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  line-height: 20px;
}

/** Team starts here **/
.team{
  width: 98.7vw;
  margin-top: 20px;
  background: rgba(248, 248, 248, 1);
  padding-top: 70px;
  padding-bottom: 70px;
  
}
.team h3{
  color:var(--secondary-color)
}
.team p{
  color:rgba(89, 88, 92, 1);
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.team-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next row when there's not enough space */
  justify-content: space-between; /* Distribute items evenly with some space between them */
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto; /* Center the container horizontally */
  width:90%;
}

.team-item {
  width: calc(25% - 10px); /* Four items in a row with 10px spacing between them */
  margin: 0 0 20px 0; /* Adjust vertical spacing between items */
  padding: 20px;
  text-align: center;
  transition: border 0.3s ease, border-radius 0.3s ease;
  border-radius: 10px;
  border:1px solid rgba(248, 248, 248, 1);
}

.team-item:hover{
  border:1px solid var(--primary-color);

}
.team-item img{
  width:100px;
  height:100px;
  border-radius: 50%;
}
.team-social{
  margin-top: 10px;
  width:30%;
  margin-left: 35%;
}
.team-social a{
  background:rgba(89, 88, 92, 1);
  border-radius: 5px;
}
.s-link{
  color:#fff;
  padding:5px

}
/** Team ends here **/

/** Mission and Vision Starts Here **/
.mission{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 98.7vw;
  background: #F1ECFC;
  padding-top: 80px;
}
.mission h3{
  color:var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.mission p{
  color:var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  padding-top: 10px;
  margin-bottom: 22px; 
  text-align: center;
}
.mission-wrapper{
  width:90%;
  margin-top: 50px;
  margin-bottom: 150px;
}
.mission-left{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  width:40%;
}
.mission-wrapper img{
  width: 120px;
  height:120px;
  margin-right: 7%;
}
.mission-wrapper h5{
  color:#100429;
  font-weight: 600;
  font-size: 16px;
  margin: 0; 
}
.mission-wrapper p{
  text-align: left;
}
.mission-right{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  width:40%;
}
.mission-right img{
  width: 120px;
  height:120px
}
/** Mission and Vision Ends Here **/

/** About Screen Components **/
.about-section{
  width: 98.7vw;
}
.about-container h3{
  color:var(--secondary-color);
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  text-align: center;
  padding-top: 3px;
}
.about-container h5{
  color:var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.about-container p{
  color:var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  padding-top: 10px;
  margin-bottom: 22px;
}

/** Commitment Starts Here **/
.commitment{
  display: flex;
  flex-direction: column;
align-items: flex-end;
justify-content: center;
  width: 98.7vw;
  min-height: 600px;
  background: url(../images/bg1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.commitment-wrapper{
  width: 35%;
  margin-right: 5%;
}
.commitment-wrapper h3{
  color:#fff;
  font-weight: 600;
font-size: 22px;
}
.commitment-wrapper p{
  color:#fff;
  margin-top: 18px;
  line-height: 23px;
}
/** Contact Start here**/
.contact-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact-left{
  width:30%;
  
}
.contact-left h3{
  color:#180401;
  font-size: 28px;
  font-weight: 600;
}
.contact-left p{
  padding-top: 10px;
  margin-bottom: 15px;
  line-height: 20px;

}
.contact-left h6{
  color:#180401;
  font-size: 15px;
  font-weight: 500;
  margin:0;
  padding-bottom: 15px;
}
.contact-left span{
  margin-top: 15px;

}
.contact-right{
  width:60%;
  background: #F1ECFC;
  padding-bottom: 50px;
}
/** Contact Ends here**/
.form-container{
width: 100%;
}
.form-wrapper{
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
width: 90%;
margin-left: 5%;
margin-top: 50px;
margin-bottom: 50px;
}
.form-group {
  width:45%;
  margin-bottom: 20px;
}
.form-group-textarea{
  width:90%;
  margin-left: 5%;
  margin-top: -50px;
  margin-bottom: 20px;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333233;
  font-size: 15px;
}

input[type="text"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #B6B2BF;
  background: none;
  border-radius: 15px;
}


textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #B6B2BF;
  border-radius: 15px;
  background: none;
  height:120px
}
input[type="text"]:focus {
  border:none;
  font-size: 16px;
}
span.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 90%;
  margin-left: 5%;
}

.btn-primary:hover {
  background-color: #100429;
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
}
.news-letter-button{
  width:15%;
  border-radius: 5px;
  margin: 0px;
}
input[type="email"] {

  width: 77%;
  padding: 10px;
  border: none;
  background: #F8F8F8;
  border-radius: 5px;
}

/** Forms ends Here **/

/** Parterns Start here**/
.partners{
  width:100%;
  margin-top: 50px;
}
.partner-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next row when there's not enough space */
  justify-content: space-between; /* Distribute items evenly with some space between them */
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto; /* Center the container horizontally */
  width:90%;
  margin-top: 30px;
}
.partner-item {
  width: calc(20% - 10px); /* Four items in a row with 10px spacing between them */
  text-align: center;
  transition: border 0.3s ease, border-radius 0.3s ease;
  border-radius: 10px;
  border:1px solid rgba(248, 248, 248, 1);
}
.partner-item img{
  width:100%;
}
/* Media query for tablets */
@media (max-width: 767px) {
  .team-item {
    width: calc(33.33% - 10px); /* Three items in a row on tablets with 10px spacing */
  }
  .partner-item {
      width: calc(20% - 10px); /* Three items in a row on tablets with 10px spacing */
    }
}

/* Call to action starts here*/
.cta{
  display: flex;
  justify-content: flex-end;
  width:100%;
  margin-top: 70px;
  background-image: url(./../images/bg4.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 70px;
  padding-bottom: 70px;
  border-radius: 10px;
  margin-bottom: -17%;
}
.cta-container{
width:35%;
margin-right: 5%;
}
.cta-container h2{
  color:#FCFCFC;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  line-height: 34px;

}
.cta-container h5{
color:#FCFCFC;
font-size: 17px;
font-weight: 500;
margin: 0;
padding-bottom: 10px;
}
.cta-container p{
  color:#FCFCFC;
  font-size: 16px;
  padding-top: 15px;
}
.cta-button{
  background: #FCFCFC;
  color:var(--primary-color);
  margin-top: 30px;
  border: none;
}
.cta-button:hover{
  background: #022D19;
}

/* Hide the mobile toggle button on screens wider than 768px (adjust the value as needed) */
@media (min-width: 768px) {
.mobile-toggle-button {
  display: none;
}

/* Hide the mobile menu on screens wider than 768px */
.mobile-menu {
  transform: translateX(-100%); /* Move the menu off-screen to the left */
}
}


/* Show the mobile toggle button on screens 768px and below */
@media (max-width: 768px) {
.mobile-toggle-button {
  position: absolute;
  top: 10px;
  right: 10px; /* Adjust the right value to position it on the right side */
  cursor: pointer;
}

/* Show the mobile menu on screens 768px and below */
.mobile-menu.open {
  transform: translateX(0); /* Slide the menu in from the left */
  background:red;
  width:200px;
}
}

/** Footer Stars Here  **/
.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width:100%;
  background: #100429;
}
.footer p{
  bottom:0px;
  color:#BBB5C8;
  margin-bottom: 30px;
}

.footer-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  width: 90%;
  margin-bottom: 50px;
}
.footer-container h3{
  color:#FCFCFC;
  font-size: 20px;
  margin-bottom: 15px;
}
.footer-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:30%;
}
.footer-left img{
  width:150px;
  margin-bottom: 20px;
}
.footer-left p{
  color:#fff;
  font-size: 16px;
  margin-bottom: 15px;
}
.footer-middle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:30%;
  margin-top: 30px;
  padding-top: 10px;
}
.footer-links{
  list-style: none;
}
.footer-links li{
  margin-bottom: 10px ;
}
.footer-anchor{
  color:#FCFCFC;
  text-align: center;
}
.footer-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:30%;
}
.footer-social{
  margin-top: 5px;
  width:30%;
}
.footer-social i{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background:#fff;
  color:var(--primary-color) !important;
  width:16px;
  height:16px;
  border-radius: 50%;
}
/** Footer Ends Here  **/
.home-clearfix{
  height: 130px;
  width: 112.4%;
  background: var(--secondary-color) !important;
  z-index: -99;
}
@media (max-width: 768px) {
  /* Styles for landscape view */
  /* CSS for MobileNav */
.mobile-navigation {
  position: fixed;
  top: 0;
  left: -100%; /* Hide the mobile navigation off-screen initially from the left */
  width: 100%; /* Set it to full width */
  height: 100%; /* Set it to full height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows the viewport */
  background-color: #fff; /* Set the background color */
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2); /* Add a shadow for separation */
  transition: left 0.7s ease; /* Adjust the transition duration as needed */
  z-index: 1000; /* Ensure it's above other content */
}

.mobile-navigation.open {
  left: 0; /* Show the mobile navigation by sliding from the left */
}
.signup-button-mobile {
  padding: 10px;
}

.signup-button-mobile button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  align-self: flex-start;

}
.signup-button-mobile button:hover {
 background:var(--secondary-color) ;
}
.mobile-nav-column{

}
.mobile-nav-column li{
  color:#7D7D7D;
  font-size: 16px;
  margin-bottom: 10px;
}
.row-mobile-nav {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
  flex-wrap: wrap; /* Wrap items to the next row on smaller screens */
  padding:5px;
}

.close-toggle-button {
 color:#180401;
 font-size: 25px;
  cursor: pointer;
  padding-top: 2px;
  margin-right: 5px;
}

.home-clearfix{
  height: 130px;
  width: 106%;
  background: none !important;
  z-index: -99;
}

/* Toggle button style */
.toggle-button {
position: absolute;
top: 10px;
left: 10px;
cursor: pointer;
}
/* Close Aside */
.open-sidebar{
display: block;
}
  .navbar{
    position: sticky;
    top: 0;
    z-index: 100;
    width:110%;
    height:50px;
    padding-top: 10px;
    margin: 0;
    background: #fbf9ff;
    padding-left: 0%;
    padding-right: 0%;
  }
  .mobile-nav{
      display: flex;
      background-color: red;
      border: none;
      color:var(--primary-color);
      font-size: 25px;
      transform: translateX(100%);
      transition: all 0.5s;
      overflow: hidden;
      right: 0%;
      width: 100vw;
      height: 100vh;
      z-index: 1000;
    
  }
  .cta{
    margin-bottom: -50%;
  }
  .cta-container{
    width:80%;
    margin-left: 10%;
    }
  .nav-links{
      display: none;
  }
 .signup-button{
  display:none;
 }
 .logo img{
  width:120px;
  margin-left: 10%;
 }
 .hero-left h3{
  font-size: 28px;
  line-height: 37px;
}
.hero-left{
  width:100%;
}
.hero-right{
  display: none;
  margin-top:20px;
}
.about-left{
  margin-top: 0px;
}
.about-left h3{
  font-size: 28px;
  line-height: 37px;
}
.features-left h3{
  font-size: 28px;
  line-height: 37px;
}
.home-clearfix{
  height: 300px;
}
.footer-social{
  width: 50%;
}
.partner-item {
  width: calc(50% - 10px); /* Four items in a row with 10px spacing between them */
}
.mission-left img{
  width:50px;
  height: 50px;
}
.mission-right img{
  width:50px;
  height: 50px;
}
.commitment-wrapper{
  margin-top: 50px;
  margin-bottom: 50px;
}
}
/* Media query for mobile devices */
@media (max-width: 480px) {

 .logo img{
  width:120px;
 }
 .welcome{
  margin-top: 50px;
}
.hero-left h3{
  font-size: 28px;
  line-height: 37px;

}
.cta-container{
  width:100%;
  background-color: rgba(0, 0, 0, 0.2) !important;
  height: 100%;
}
.team{
  width:110%
}
.about{
  width: 110%;
  padding-top: 40px !important;
  padding-bottom: 50px !important;
}
.features{
  width:110%;
}
  .team-item {
    width: 100%; /* One item in a row on mobile devices */
  }
  .partner-item{
      width: calc(50% - 10px); /* Four items in a row with 10px spacing between them */
  }
  .hero-left{
      width:100%;
  }
  .hero-right{
      width:100%;
      margin-top:20px;
  }
  .hero-image{
      width:90%;
      margin-left: 5%;
      margin-top: 5px;
  }
  .about{
      margin-top: 30px;
      padding-top: 0px;
      padding-bottom: 0px;
  }
  .about-left{
      width:100%;
  }
  .products-row{
    align-items: center;
    justify-content: center;
  }
  .products{
    width: 110%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .products h3{
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
}
  .row-left{
    width:90%;
}
.row-right{
  width:90%;
}
.product-image{
  width: 100%;
  margin-top: 20px;
}
.products li{
  list-style:circle;
  font-size: 16px;
  margin-bottom: 5px;
}
.mobile-row-reverse{
  flex-direction: column-reverse;
}
  .about-left h3{
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
  }
  .about-right{    
   width:100%;
   margin-top: 30px;
  }
  .features{
      margin-top: 10px;
  }
  .features-left{
      width:100%;
      margin-top: 50px;
      
  }
  .features-left h3{
      font-size: 28px;
      line-height: 38px;
      margin: 0;
      padding-top: 3px;
  }
  .features-right{
      width:100%;
      margin-top: 30px;
  }
  .features-wrapper{
      margin-bottom: 0px;
      padding-top:30px;
      padding-bottom: 30;
      padding-left: 10px;
  }
  .team{
      padding-bottom: 20px;
      
  }
  .team-social{
      margin-top: 0px;
  }
  .about-section{
    padding-top: 40px;
  }
  .about-container h3{
font-size: 25px;
line-height: 35px;

  }
  .about-left{
    margin-top: 10px;
  }
  .mission{
    margin-top: 0px;
  }
  .mission-wrapper{
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .mission-wrapper img{
    width: 30px;
    height:30px;
    margin-top: 10px;
  }
  .mission-left h5{
    margin-bottom: -5px;
  }
  .mission-left{
    width:100%;
  }
  .mission-right{
    width:100%;
  }
  .commitment-wrapper{
    width: 90%;
  }
  .commitment{
    background-size: cover;
    background-position: left;
    width:112%
  }
.cta{
  background-size: cover;
}
.home-clearfix{
  height: 130px;
  width: 100vw;
  background: var(--secondary-color) !important;
  z-index: -99;
}
  .contact-wrapper{
  flex-direction: column;
  }
  .contact-left{
    width:100%; 
  }
  .contact-right{
    width:100%; 
    margin-top: 20px;
  }
  .form-group {
    width:100%;
  }
  .footer-container{
    flex-direction: column;
  }
  .footer-left{
    width:100%;
  }
  .footer-middle{
    align-items: flex-start;
    width:100%;
    margin-top: 30px;
  }
  .footer-social{
    margin-left: 1%;
  }
  .footer-links{
    margin-left: -5%;
  }
  .footer-anchor{
    font-size: 16px;
  }
  .footer-right{
    width:100%;
    margin-top: 20px;
  }
  .home-clearfix{
    height: 150px;
    background: #100429;
  }
}


